import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'

export namespace OGCAPIFeaturesAPI {
  export class GetLayers implements APIRequest<string> {
    response: string;
    path = null;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data;
    hideNotification = true;
    headers = [];

    constructor (serviceUrl: string) {
      this.path = serviceUrl + '/collections'
    }
  }
}
